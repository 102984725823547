"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.array.concat.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    attrs: {
      id: "upload-control"
    }
  }, [_c("el-upload", {
    style: "width: ".concat(_vm.computeWidth, ";\n  height: ").concat(_vm.computeHeight, ";margin:0 auto"),
    attrs: {
      name: "file",
      drag: "",
      "before-upload": _vm.beforeUpload,
      action: _vm.$baseUrl + _vm.urlUpload,
      "on-success": _vm.handleSuccess,
      "show-file-list": false,
      headers: {
        token: _vm.token
      },
      "on-progress": _vm.onProgress,
      "on-error": _vm.onError,
      disabled: _vm.disabled
    }
  }, [_vm.isProgressing ? _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.percentUpload
    }
  }) : _vm._e(), !_vm.isProgressing ? [_vm.pathImage ? _c("img", {
    staticClass: "image",
    staticStyle: {
      "object-fit": "contain"
    },
    style: "width:".concat(_vm.width, "px;height:").concat(_vm.height, "px"),
    attrs: {
      src: _vm.$baseUrlMedia + _vm.pathImage,
      alt: ""
    }
  }) : [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" Kéo thả vào đây hoặc "), _c("em", [_vm._v("click vào để upload")])])]] : _vm._e()], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;