"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-card", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("info")))])]), _c("div", {
    staticClass: "user-profile"
  }, [_c("div", {
    staticClass: "box-center"
  }, [_c("pan-thumb", {
    attrs: {
      image: _vm.user.avatar ? _vm.$baseUrl + _vm.user.avatar : require("@/assets/images/avatar_default.svg"),
      height: "100px",
      width: "100px",
      hoverable: false
    }
  }, [_c("div", [_vm._v("Xin chào")]), _vm._v(" " + _vm._s(_vm.user.name) + " ")])], 1), _c("div", {
    staticClass: "box-center"
  }, [_c("div", {
    staticClass: "user-name text-center"
  }, [_vm._v(_vm._s(_vm.user.name))])])]), _c("div", {
    staticClass: "user-bio"
  }, [_c("div", {
    staticClass: "user-education user-bio-section"
  }, [_c("div", {
    staticClass: "user-bio-section-header"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "education"
    }
  }), _c("span", [_vm._v(_vm._s(_vm.$t("otherInfo")))])], 1), _c("div", {
    staticClass: "user-bio-section-body"
  }, [_c("div", {
    staticClass: "text-muted"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.$t("phone")) + ": ")]), _vm._v(" " + _vm._s(_vm.user.phone) + " ")]), _c("div", {
    staticClass: "text-muted"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Email: ")]), _vm._v(" " + _vm._s(_vm.user.email) + " ")]), _c("div", {
    staticClass: "text-muted"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")) + ": ")]), _vm._v(" " + _vm._s(_vm.user.address) + " ")])])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;