"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/core-js/modules/es.array.iterator.js");
require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/core-js/modules/es.promise.js");
require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/core-js/modules/es.object.assign.js");
require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
require("@/plugins/input-mask");
var _App = _interopRequireDefault(require("./App.vue"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/plugins/qr-generator");
require("@/plugins/element");
require("@/plugins/basic-vue-chat");
require("./icons");
require("./permission");
var _i18n = _interopRequireDefault(require("@/plugins/i18n"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _date = require("@/utils/date");
var _format = require("@/utils/format");
var _BInputNumber = _interopRequireDefault(require("@/components/Input/BInputNumber.vue"));
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _InputMoney = _interopRequireDefault(require("@/components/InputMoney/InputMoney.vue"));
var _SingleImage = _interopRequireDefault(require("@/components/Upload/SingleImage.vue"));
var filters = _interopRequireWildcard(require("./filters"));
var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");
var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");
var _vueFontawesome = require("@fortawesome/vue-fontawesome");
// a modern alternative to CSS resets

// global css

//google map
// import  "@/plugins/google-maps";
//
// icon
// permission control
_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;
_vue.default.prototype.$formatNumberVN = _format.formatNumberVN;
_vue.default.prototype.$secondToMinutes = _format.secondToMinutes;
_vue.default.component("BInputNumber", _BInputNumber.default);
_vue.default.component("Pagination", _index2.default);
_vue.default.component("InputMoney", _InputMoney.default);
_vue.default.component('single-image', _SingleImage.default);
// global filters

_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faPlay, _freeSolidSvgIcons.faPause, _freeSolidSvgIcons.faClock, _freeSolidSvgIcons.faVolumeUp, _freeSolidSvgIcons.faVolumeMute, _freeSolidSvgIcons.faMotorcycle, _freeSolidSvgIcons.faTruck, _freeSolidSvgIcons.faUserTag, _freeSolidSvgIcons.faUtensils, _freeSolidSvgIcons.faPeopleCarry, _freeSolidSvgIcons.faUserSecret, _freeSolidSvgIcons.faDollarSign, _freeSolidSvgIcons.faTicketAlt, _freeSolidSvgIcons.faShoppingCart, _freeSolidSvgIcons.faUserNurse, _freeSolidSvgIcons.faProcedures, _freeSolidSvgIcons.faAssistiveListeningSystems, _freeSolidSvgIcons.faShare, _freeSolidSvgIcons.faHeart);
_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);
_vue.default.use(_elementUi.default, {
  size: "small" // set element-ui default size
});

_vue.default.use(_vueClipboard.default);
// register global utility filters
Object.keys(filters).forEach(function (key) {
  // @ts-ignore 
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = process.env.VUE_APP_CONFIG_SYSTEM == "true";
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.config.productionTip = false;
Object.defineProperty(_vue.default.prototype, "$tstore", {
  get: function get() {
    return this.$store;
  },
  enumerable: true
});
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  i18n: _i18n.default,
  render: function render(h) {
    return h(_App.default);
  }
});