"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.handleCreate
    }
  }, [_vm._v(_vm._s(_vm.$t("add")))])], 1), _c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: _vm.$t("name"),
      prop: "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", {
          staticClass: "img-box"
        }, [_c("img", {
          attrs: {
            src: _vm.$baseUrlMedia + row.image,
            alt: ""
          }
        })]), _c("label", [_vm._v(_vm._s(row.name))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("action")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-tooltip", {
          attrs: {
            content: _vm.$t("update"),
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            icon: "el-icon-info",
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleUpdate(row);
            }
          }
        })], 1), _c("el-tooltip", {
          attrs: {
            content: _vm.$t("remove"),
            placement: "top",
            effect: "dark"
          }
        }, [_c("el-button", {
          attrs: {
            icon: "el-icon-delete",
            type: "warning",
            size: "mini",
            loading: row.loading
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        })], 1)];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  }), _c("UtilityModal", {
    ref: "utilityModal",
    on: {
      "submit:success": _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;