"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-table", {
    attrs: {
      data: _vm.data,
      border: "",
      fit: "",
      "show-summary": true,
      "summary-method": _vm.getSummaries
    }
  }, [_c("el-table-column", {
    attrs: {
      label: _vm.$t("orderCode"),
      prop: "order.code"
    }
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("customer")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_vm._v(_vm._s(row.order.customer.name))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("phone")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v(_vm._s(row.order.customer.phone))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("quantity")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v(_vm._s(_vm._f("toThousandFilter")(row.quantity)))];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: _vm.$t("amount")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v(_vm._s(_vm._f("toThousandFilter")(row.finalPrice)))];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.query.page,
      limit: _vm.query.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.query, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.query, "limit", $event);
      },
      pagination: _vm.fetchData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;