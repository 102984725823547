"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkAccessRoute = checkAccessRoute;
exports.default = checkPermission;
exports.generateRoutesPath = generateRoutesPath;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
var _store = _interopRequireDefault(require("@/store"));
var _router = require("@/router");
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = _store.default.getters && _store.default.getters.roles;
    var permissionRoles = value;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    // eslint-disable-next-line no-console
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}
function generateRoutesPath() {
  var tmp = JSON.stringify(_router.constantRoutes);
  var res = JSON.parse(tmp);
  var routes = res.filter(function (route) {
    return route.meta && route.meta.permission;
  });
  var _iterator = (0, _createForOfIteratorHelper2.default)(routes),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var route = _step.value;
      delete route.component;
      var _iterator2 = (0, _createForOfIteratorHelper2.default)(route.children),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var child = _step2.value;
          delete child.component;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return routes;
}
function checkAccessRoute(path) {
  var routes = _store.default.getters.permission_routes;
  var res = routes.some(function (item) {
    return item.path == path;
  });
  if (res) {
    return res;
  } else {
    if (routes.length) {
      return routes[0].path;
    } else {
      return "/404";
    }
  }
}