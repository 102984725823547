"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BarChartCustom = _interopRequireDefault(require("../BarChartCustom"));
var dashbord = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
var _default = exports.default = {
  components: {
    BarChart: _BarChartCustom.default
  },
  data: function data() {
    return {
      variable: _variables.default,
      date: [(0, _moment.default)().startOf("month").startOf("days").format("YYYY-MM-DD"), (0, _moment.default)().endOf("month").endOf("days").format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
    console.log("mau ne", this.variable);
  },
  methods: {
    convertDataBar: function convertDataBar(item) {
      return item.total.moneyFinal;
    },
    getDataChart: function getDataChart() {
      var _this = this;
      var listQuery = {
        from: this.date[0],
        to: this.date[1]
      };
      dashbord.orderShoppingChart(listQuery).then(function (res) {
        _this.dataChart = res.data.report;
        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};