"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container",
    attrs: {
      id: "news"
    }
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v(_vm._s(_vm.$t("add")))])], 1), _c("el-tabs", {
    attrs: {
      type: "card",
      "tab-position": "top"
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Tin tức",
      name: "news"
    }
  }, [_c("NewsPage", {
    attrs: {
      isHighlight: null,
      onUpdate: _vm.handleEdit,
      refreshData: _vm.refreshData
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "Banner",
      name: "banner"
    }
  }, [_c("NewsPage", {
    attrs: {
      isHighlight: true,
      onUpdate: _vm.handleEdit,
      refreshData: _vm.refreshData
    }
  })], 1)], 1), _c("news-dialog", {
    ref: "dialog",
    on: {
      "create:success": _vm.getTableData,
      "update:success": _vm.getTableData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;