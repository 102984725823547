"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "form",
    staticStyle: {
      "padding-bottom": "24px"
    },
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "title",
      label: "Tiêu đề"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "contactName",
      label: "Người liên hệ"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contactName", $$v);
      },
      expression: "form.contactName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "contactPhone",
      label: "SĐT liên hệ"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contactPhone", $$v);
      },
      expression: "form.contactPhone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Loại tin",
      prop: "postType"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.postType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "postType", $$v);
      },
      expression: "form.postType"
    }
  }, _vm._l(_vm.PostType, function (item, index) {
    return _c("el-option", {
      key: "".concat(index),
      attrs: {
        label: item,
        value: index
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Quận/huyện",
      prop: "addressDistrictId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeDistrict
    },
    model: {
      value: _vm.form.addressDistrictId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "addressDistrictId", $$v);
      },
      expression: "form.addressDistrictId"
    }
  }, _vm._l(_vm.districts, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "address",
      label: "Địa chỉ"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích đất",
      prop: "area"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.area,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "area", $$v);
      },
      expression: "form.area"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Giá tiền",
      prop: "price"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Loại phòng",
      prop: "roomType"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.roomType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roomType", $$v);
      },
      expression: "form.roomType"
    }
  }, _vm._l(_vm.RoomType, function (item, index) {
    return _c("el-option", {
      key: "".concat(index),
      attrs: {
        label: item,
        value: index
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Tỉnh/thành",
      prop: "addressCityId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    on: {
      change: _vm.handleChangeCity
    },
    model: {
      value: _vm.form.addressCityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "addressCityId", $$v);
      },
      expression: "form.addressCityId"
    }
  }, _vm._l(_vm.cities, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "Phường/xã",
      prop: "addressWardId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    model: {
      value: _vm.form.addressWardId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "addressWardId", $$v);
      },
      expression: "form.addressWardId"
    }
  }, _vm._l(_vm.wards, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dịch vụ",
      prop: "categoryIds"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      multiple: "",
      size: "small"
    },
    model: {
      value: _vm.form.utilIds,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "utilIds", $$v);
      },
      expression: "form.utilIds"
    }
  }, _vm._l(_vm.utils, function (item) {
    return _c("el-option", {
      key: "".concat(item.id),
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("description")
    }
  }, [_c("el-input", {
    attrs: {
      rows: 10,
      type: "textarea"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _c("label", {
    staticStyle: {
      "font-size": "22px"
    },
    attrs: {
      for: ""
    }
  }, [_vm._v("Thông tin khác")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Diện tích sử dụng"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.areaUse,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "areaUse", $$v);
      },
      expression: "form.areaUse"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số phòng ngủ"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.bedRoom,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bedRoom", $$v);
      },
      expression: "form.bedRoom"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số phòng vệ sinh"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.toilet,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "toilet", $$v);
      },
      expression: "form.toilet"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giấy tờ pháp lý"
    }
  }, [_c("el-select", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.legal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "legal", $$v);
      },
      expression: "form.legal"
    }
  }, _vm._l(_vm.LegalTrans, function (item, key) {
    return _c("el-option", {
      key: key,
      attrs: {
        label: item,
        value: key
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Số tầng lầu"
    }
  }, [_c("el-input", {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.floor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "floor", $$v);
      },
      expression: "form.floor"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Dự án"
    }
  }, [_c("el-input", {
    attrs: {
      size: "medium"
    },
    model: {
      value: _vm.form.projectName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "projectName", $$v);
      },
      expression: "form.projectName"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thumbnail",
      prop: "image"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.form.image
    },
    on: {
      "upload:success": _vm.handleUpdateSuccessThumbnail
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("image") + " ".concat(_vm.$t("ratio"), " (1 : 1)")
    }
  }, [_c("gallery-product", {
    ref: "galleryProduct",
    attrs: {
      onChangeGalaries: _vm.onChangeGalaries
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;