"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "card-panel",
    staticStyle: {
      height: "120px"
    }
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "people-carry",
      size: "3x"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(_vm._s(_vm.$t("today")))]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("order")) + ":")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.today.totalOrder,
      duration: 3200
    }
  })], 1)])])]), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "card-panel",
    staticStyle: {
      height: "120px"
    }
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "user-tag",
      size: "3x"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(_vm._s(_vm.$t("customer")))]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("total")) + ":")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dataSouceCustomer.totalCustomer,
      duration: 3200
    }
  })], 1), _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("today")) + ":")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.dataSouceCustomer.totalCustomerToday,
      duration: 3200
    }
  })], 1)])])])], 1), _c("hr"), _c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      type: "daterange",
      "range-separator": "To",
      format: "dd/MM/yyyy",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": _vm.$t("startDate"),
      "end-placeholder": _vm.$t("endDate")
    },
    on: {
      change: _vm.getData
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "card-panel",
    staticStyle: {
      height: "175px"
    }
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: "shopping-cart",
      size: "3x"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(_vm._s(_vm.$t("shoppingOrder")))]), _c("span", [_vm._v(_vm._s(_vm.$t("total")))]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.orderShopping.total,
      duration: 3200
    }
  }), _c("hr"), _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("orderPending")))]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.orderShopping.totalPending,
      duration: 3200
    }
  })], 1), _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("orderDelivering")))]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.orderShopping.totalDelivering,
      duration: 3200
    }
  })], 1), _c("div", [_c("span", [_vm._v(_vm._s(_vm.$t("orderComplete")))]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.orderShopping.totalComplete,
      duration: 3200
    }
  })], 1)], 1)])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;