"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container",
    attrs: {
      id: "notification"
    }
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("Thêm")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    attrs: {
      data: _vm.tableData,
      border: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      "class-name": "text-center",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Tiêu đề"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Nội dung",
      prop: "body"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Bài đăng / Tin tức"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [row.type == _vm.NotificationType.Post ? _c("img", {
          staticStyle: {
            "border-radius": "8px"
          },
          attrs: {
            width: "150",
            src: _vm.$baseUrlMedia + row.post.image,
            alt: ""
          }
        }) : _vm._e(), row.type == _vm.NotificationType.News ? _c("img", {
          staticStyle: {
            "border-radius": "8px"
          },
          attrs: {
            width: "150",
            src: _vm.$baseUrlMedia + row.news.image,
            alt: ""
          }
        }) : _vm._e(), _c("div", {
          staticStyle: {
            "font-weight": "bold"
          }
        }, [_vm._v(" " + _vm._s(row.title) + " (" + _vm._s(row.type == _vm.NotificationType.Post ? "Bài đăng" : "Tin tức") + ") ")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Gửi thông báo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("span", [_vm._v("Gửi lần cuối: " + _vm._s(_vm.$formatDateTime(row.publishAt)) + " ")]), _c("br"), _c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            loading: row.loadingPublish
          },
          on: {
            click: function click($event) {
              return _vm.handlePublish(row);
            }
          }
        }, [_vm._v("Gửi thông báo")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "Hành động"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleEdit(row);
            }
          }
        }, [_vm._v("Cập nhật")]), _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(row);
            }
          }
        }, [_vm._v("Xóa")])];
      }
    }])
  })], 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getTableData
    }
  }), _c("NotificationDialog", {
    ref: "dialog",
    on: {
      "update:success": _vm.getTableData,
      "create:success": _vm.getTableData
    }
  }), _c("NewsDialog", {
    ref: "news-dialog",
    attrs: {
      disableForm: true
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;