"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
_vue.default.use(_vueRouter.default);
/* Layout */

/* Router Modules */
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _index.default,
  redirect: "/dashboard/index",
  meta: {
    title: "Dashboard",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/admin/index.vue"));
      });
    },
    name: "dashboard-index",
    meta: {
      title: "Dashboard",
      permission: true
    }
  }]
}, {
  path: "/customer",
  component: _index.default,
  redirect: "/customer/index",
  meta: {
    title: "customer",
    icon: "peoples",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index.vue"));
      });
    },
    name: "customer-index",
    meta: {
      title: "customer",
      permission: true
    }
  }, {
    path: "profile",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer-profile/index.vue"));
      });
    },
    name: "customer-profile-index",
    meta: {
      title: "Hồ sơ kyc",
      permission: true
    }
  }]
}, {
  path: "/post",
  component: _index.default,
  redirect: "/post/index",
  meta: {
    title: "Bài đăng",
    icon: "documentation",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/post/index.vue"));
      });
    },
    name: "post-index",
    meta: {
      title: "Bài đăng",
      permission: true
    }
  }]
},
// {
//   path: "/order",
//   component: Layout,
//   redirect: "/order/index",
//   meta: {
//     title: "order",
//     icon: "documentation",
//     permission: true
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/order/index.vue"),
//       name: "order-index",
//       meta: {
//         title: "order",
//         permission: true
//       },
//     },
//     {
//       path: "config-point",
//       component: () => import("@/views/config-order/index.vue"),
//       name: "config-point-order",
//       meta: {
//         title: "configPoint",
//         permission: true
//       },
//     },
//     {
//       path: "ship-fee",
//       component: () => import("@/views/address-ship/index.vue"),
//       name: "order-ship-fee",
//       meta: {
//         title: "shipFee",
//         permission: true
//       },
//     },
//   ]
// },
// {
//   path: "/product",
//   component: Layout,
//   redirect: "/product/index",
//   meta: {
//     title: "product",
//     icon: "shopping-bag",
//     permission: true
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/product/index.vue"),
//       name: "product-index",
//       meta: {
//         title: "product",
//         permission: true
//       },
//     },
//     {
//       path: "category",
//       component: () => import("@/views/category-product/index.vue"),
//       name: "product-category",
//       meta: {
//         title: "category",
//         permission: true
//       },
//     },
//     {
//       path: "brand-name",
//       component: () => import("@/views/brand/index.vue"),
//       name: "product-brand-name",
//       meta: {
//         title: "brandName",
//         permission: true
//       },
//     },
//   ],
// },
// {
//   path: "/promotion",
//   component: Layout,
//   redirect: "/promotion/index",
//   meta: {
//     title: "promotion",
//     icon: "percent-solid",
//     permission: true
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/coupon/index.vue"),
//       name: "promotion-index",
//       meta: {
//         title: "promotion",
//         permission: true
//       },
//     },]
// },
{
  path: "/utility",
  component: _index.default,
  redirect: "/utility/index",
  meta: {
    title: "Dịch vụ",
    icon: "shopping-bag",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/utility/index.vue"));
      });
    },
    name: "utility-index",
    meta: {
      title: "Dịch vụ",
      permission: true
    }
  }]
}, {
  path: "/media",
  component: _index.default,
  redirect: "/media/index",
  meta: {
    title: "Danh sách ảnh",
    icon: "photo-video-solid",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/media/index.vue"));
      });
    },
    name: "media-index",
    meta: {
      title: "Danh sách ảnh",
      permission: true
    }
  }]
}, {
  path: "/news",
  component: _index.default,
  redirect: "/news/index",
  meta: {
    title: "news",
    icon: "notification-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news/index.vue"));
      });
    },
    name: "news-index",
    meta: {
      title: "news",
      permission: true
    }
  }, {
    path: "category",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/news-category/index.vue"));
      });
    },
    name: "news-category",
    meta: {
      title: "Loại tin tức",
      permission: true
    }
  }]
}, {
  path: "/notification",
  component: _index.default,
  redirect: "/notification/index",
  meta: {
    title: "Thông báo",
    icon: "notification-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/notification/index.vue"));
      });
    },
    name: "notification-index",
    meta: {
      title: "Thông báo",
      permission: true
    }
  }]
}, {
  path: "/content",
  component: _index.default,
  redirect: "/content/index",
  meta: {
    title: "content",
    icon: "control-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/content/index.vue"));
      });
    },
    name: "content-index",
    meta: {
      title: "content",
      permission: true
    }
  }]
},
// {
//   path: "/config",
//   component: Layout,
//   redirect: "/config/index",
//   meta: {
//     title: "config",
//     icon: "setting-fill",
//     permission: true
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/config/index.vue"),
//       name: "config-index",
//       meta: {
//         title: "config",
//         permission: true
//       },
//     },]
// },
{
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    title: "profile",
    icon: "qq",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "profile",
      permission: false
    }
  }]
}, {
  path: "/staff",
  component: _index.default,
  redirect: "/staff/index",
  meta: {
    title: "staff",
    icon: "tree",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/index.vue"));
      });
    },
    name: "staff-index",
    meta: {
      title: "staff",
      permission: true
    }
  }]
}, {
  path: "/role",
  component: _index.default,
  redirect: "/role/index",
  meta: {
    title: "role",
    icon: "fire-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/permission/role.vue"));
      });
    },
    name: "role-index",
    meta: {
      title: "role",
      permission: true
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
}
var _default = exports.default = router;