"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? _vm.$t("add") : _vm.$t("update"),
      visible: _vm.visible,
      fullscreen: "",
      top: "30px",
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      },
      close: _vm.handleClose
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "top"
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "info",
      label: _vm.$t("info")
    }
  }, [_c("product-info-form", {
    ref: "productInfoForm",
    attrs: {
      utils: _vm.utils,
      form: _vm.form,
      rules: _vm.rules,
      handleUpdateSuccessThumbnail: _vm.handleUpdateSuccessThumbnail,
      onChangeGalaries: _vm.onChangeGalaries
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Đóng")]), _vm.tabName == "info" ? [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        return _vm.updateData();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("accept")))])] : _vm._e()], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;