"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [!_vm.tableData.length ? _c("no-data") : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.tableData, function (item) {
    return _c("el-col", {
      key: item.id,
      attrs: {
        span: 6,
        offset: 0
      }
    }, [_c("news-item", {
      attrs: {
        onDelete: _vm.handleBlock,
        onUpdate: _vm.onUpdate,
        onHightlight: _vm.handleHightlight,
        item: item
      }
    })], 1);
  }), 1), _c("pagination", {
    attrs: {
      total: _vm.total,
      pageSizes: [12, 24, 48, 72],
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getTableData
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;