"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vi = void 0;
var vi = exports.vi = {
  fullname: "Họ tên",
  gender: "Giới tính",
  phone: "Số điện thoại",
  dob: "Ngày sinh",
  find: "Tìm",
  createdAt: "Ngày tạo",
  action: "Hành động",
  detail: "Chi tiết",
  lock: "Khóa",
  unlock: "Mở khóa",
  customer: "Khách hàng",
  product: "Sản phẩm",
  total: "Tổng",
  today: "Hôm nay",
  add: "Thêm",
  update: "Cập nhật",
  info: "Thông tin",
  close: "Đóng",
  accept: "Đồng ý",
  customerPlaceholder: "Tìm tên khách hàng",
  description: "Mô tả",
  order: "Đơn hàng",
  transport: "Gọi xe",
  collapse: "Thu gọn",
  seeMore: "Xem thêm",
  originPrice: "Giá gốc",
  priceAfterPromotion: "Giá KM",
  priceBeforePromotion: "Giá trước KM",
  brand: "Hãng",
  style: "Kiểu dáng",
  warranty: "Bảo hành",
  warrantyType: "Loại bảo hành",
  warrantyPeriod: "Thời hạn bảo hành",
  productPlaceholder: "Tìm tên SP",
  orderCode: "Mã đơn hàng",
  customerAddress: "Địa chỉ giao",
  cost: "Chi phí",
  paymentType: "Phương thức thanh toán",
  moneyTotal: "Tạm tính",
  moneyDistance: "Tiền vận chuyển",
  moneyDiscount: "Giảm giá",
  moneyFinal: "Tổng cộng",
  CASH: "Tiền mặt",
  POINT: "Điểm",
  PENDING: "Chờ duyệt",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  ADMIN_CANCEL: "Admin Huỷ",
  galaries: "Danh sách ảnh",
  remove: "Xóa",
  warning: "Cảnh báo",
  status: "Trạng thái",
  FIND_DRIVER: "Tìm t.xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  NOT_FOUND_DRIVER: "Không tìm thấy t.xế",
  DRIVER_CANCEL: "T.xế hủy",
  CUSTOMER_CANCEL: "Khách hủy",
  SCHEDULE: "Đặt lịch (Mới)",
  startDate: "Ngày bắt đầu",
  endDate: "Ngày kết thúc",
  route: "Lộ trình",
  from: "Từ",
  to: "Đến",
  profit: "Lợi nhuận",
  driver: "Tài xế",
  orderTransportPlaceholder: "Tìm code, tên, sđt",
  chooseDate: "Chọn ngày",
  totalRevenue: "Tổng lợi nhuận",
  totalIncome: "Tổng chiết khấu",
  commissionDriver: "Chiết khấu tài xế",
  kmNumber: "Số Km",
  amountInKm: "Số tiền theo Km",
  transportConfig: "Cấu hình cước phí",
  vehicleType: "Loại xe",
  pricePerKM: "Giá mỗi Km",
  minPrice: "Giá cước lăn bánh",
  kmMinPrice: "Số Km đầu tiên",
  BIKE: "Xe máy",
  BIKE_VIP: "Xe máy (VIP)",
  CAR: "Xe hơi",
  vehicle: "Phương tiện",
  username: "Tài khoản",
  balance: "Số dư",
  vehicleName: "Tên xe",
  licensePlate: "Biển số",
  changePass: "Đổi mật khẩu",
  FREE: "Đang hoạt động",
  BUSY: "Không hoạt động",
  HAVING_ORDER: "Đang có đơn",
  driverPlaceholder: "Tìm tên,sđt, biển số",
  password: "Mật khẩu",
  dragImage: "Kéo thả vào đây hoặc",
  clickUpload: "click vào để upload",
  orderHistory: "Lịch sử đơn hàng",
  payHistory: "Lịch sử nạp/rút",
  transactionHistory: "Tài khoản",
  change: "Thay đổi",
  balanceAfterChange: "Tài khoản sau khi thay đổi",
  type: "Loại",
  DEPOSIT: "Nạp tiền",
  WITHDRAW: "Rút tiền",
  INCOME: "Tiền dịch vụ",
  amount: "Số tiền",
  DRIVER: "Nạp bằng ví điện tử ",
  STAFF: "Nạp bằng tiền mặt",
  form: "Hình thức",
  depositCode: "Mã nạp",
  depositNow: "Nạp tiền ngay",
  withdrawCode: "Mã rút",
  withdrawNow: "Rút tiền ngay",
  commission: "Hoa hồng",
  note: "Ghi chú",
  distance: "Khoảng cách",
  value: "Giá trị",
  COMMISSION_TRANSPORT: "Hoa hồng tài xế",
  MIN_DRIVER_BALANCE: "Số dư tối thiểu",
  DISTANCE_FIND_DRIVER: "Khoảng cách tìm tài xế",
  RATE_POINT: "Tỷ lệ quy đổi điểm",
  CASHBACK_PERCENT: "Phần trăm hoàn trả",
  reward: "Thưởng",
  notification: "Thông báo",
  successfully: "Thành công",
  title: "Tiêu đề",
  content: "Nội dung",
  ABOUT: "Giới thiệu",
  FAQ: "Các vấn đề thường gặp",
  HOW_TO_USE: "Hướng dẫn sử dụng",
  SECURITY: "Chính sách bảo mật",
  TERM_CONDITION: "Điều khoản sử dụng",
  JOIN_RULE: "RUThể lệ tham giaLES",
  INSURANCE_PACKAGE: "Bảo hiểm",
  block: "Khóa",
  unblock: "Mở khóa",
  promotion: "Khuyến mãi",
  promotionShopping: "Khuyến mãi mua hàng",
  promotionCallDriver: "Khuyến mãi gọi tài xế",
  time: "Thời gian",
  conditionApply: "Điều kiện áp dụng",
  promotionAmount: "Giảm giá",
  totalMinimum: "Giá trị đơn hàng áp dụng",
  kmMinimum: "Số km tối thiểu",
  promotionType: "Loại khuyến mãi",
  promotionValue: "Giá trị khuyến mãi",
  PRICE: "Theo giá",
  PERCENT: "Theo phần trăm",
  role: "Vai trò",
  changePassword: "Đổi mật khẩu",
  oldPassword: "Mật khẩu cũ",
  newPassword: "Mật khẩu mới",
  reNewPassword: "Nhập lại mật khẩu mới",
  staff: "Nhân viên",
  licensePlateImage: "Ảnh bằng lái",
  productList: 'Danh sách sản phẩm',
  image: 'Hình ảnh',
  ratio: 'Tỷ lệ',
  search: 'Tìm kiếm',
  liveStreamInfo: 'Thông tin live',
  watch: 'Xem',
  seller: 'Người bán',
  start: "Bất đầu",
  end: 'Kết thúc',
  addPromotion: 'Thêm khuyến mãi',
  updatePromotion: 'Cập nhật khuyến mãi',
  deletePromotion: 'Xóa khuyến mãi',
  startLive: 'Bắt đầu livestream',
  endLive: 'Kết thúc livestream',
  thumbnail: 'Ảnh nền',
  introduce: 'Giới thiệu',
  searchOrderCustomer: 'Tìm đơn, khách hàng',
  searchProvinceName: 'Tìm tên tỉnh, thành',
  province: 'Tỉnh thành',
  shipFee: 'Phí vận chuyển',
  save: 'Lưu',
  cancel: 'Hủy',
  districtList: 'Danh sách quận, huyện',
  district: 'Quận, huyện',
  category: 'Danh mục',
  infoBasic: 'Thông tin cơ bản',
  productProperty: 'Thuộc tính sản phẩm',
  continue: 'Tiếp tục',
  goBack: 'Quay lại',
  productPropertyConfig: 'Cấu hình thuộc tính',
  propertyName: 'Tên thuộc tính',
  propertyValue: 'Giá trị thuộc tính',
  quantity: 'Số lượng',
  position: 'Vị trí',
  backgroundColor: 'Màu nền',
  expire: 'Hạn dùng',
  otherInfo: 'Thông tin khác',
  address: 'Địa chỉ',
  shoppingOrder: 'Đơn hàng shipping',
  orderPending: 'Đơn chờ duyệt',
  orderDelivering: 'Đơn đang giao',
  orderComplete: 'Đơn hoàn tất',
  customerChart: 'Biểu đồ khách hàng',
  orderChart: 'Biểu đồ đơn hàng',
  hotDeal: 'Hot deal',
  livestream: 'Livestream',
  news: 'Tin tức',
  profile: 'Cá nhân',
  point: 'Điểm',
  configPoint: 'Cấu hình điểm',
  name: 'Tên',
  price: 'Giá bán',
  confirmDelete: 'Bạn chắc chắn muốn xóa dữ liệu này?',
  brandName: 'Thương hiệu',
  buyAt: 'Ngày mua',
  viewRate: 'Xem đánh giá',
  rate: 'Đánh giá',
  config: 'Cấu hình',
  SOUND_CLAIM_PROMOTION: 'Âm thanh nhận khuyến mãi'
};