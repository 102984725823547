// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#f44336",
	"menuText": "#9aaec5",
	"menuActiveText": "#1596d2",
	"subMenuActiveText": "white",
	"menuBg": "#001429",
	"menuHover": "#001429",
	"subMenuBg": "#001429",
	"subMenuHover": "#198fff",
	"sideBarWidth": "240px"
};
module.exports = exports;
