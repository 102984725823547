"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: "Thông tin KYC",
      visible: _vm.dialogVisible,
      width: "700px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      "label-position": "top",
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "80px"
    }
  }, [_vm.formData.customerProfile ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ảnh mặt trước CMND / CCCD"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.formData.customerProfile.imageIdCardBefore,
      disabled: true
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ảnh mặt sau CMND / CCCD"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.formData.customerProfile.imageIdCardAfter,
      disabled: true
    }
  })], 1)], 1), _vm.formData.customerProfile.companyName ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên công ty"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.formData.customerProfile.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.customerProfile, "companyName", $$v);
      },
      expression: "formData.customerProfile.companyName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã số thuế",
      prop: "taxCode"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.formData.customerProfile.taxCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.customerProfile, "taxCode", $$v);
      },
      expression: "formData.customerProfile.taxCode"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Người đại diện pháp luật",
      prop: "representationName"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.formData.customerProfile.representationName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.customerProfile, "representationName", $$v);
      },
      expression: "formData.customerProfile.representationName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.formData.customerProfile.companyAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.customerProfile, "companyAddress", $$v);
      },
      expression: "formData.customerProfile.companyAddress"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giấy phép kinh doanh"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.formData.customerProfile.imageBusinessLicense,
      disabled: true
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "danger",
      plain: "",
      loading: _vm.submitLoading
    },
    on: {
      click: function click($event) {
        return _vm.cancelData();
      }
    }
  }, [_vm._v("Huỷ")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: function click($event) {
        return _vm.acceptData();
      }
    }
  }, [_vm._v("Duyệt")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;