"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.VehicleType = exports.TransactionType = exports.TYPE_CONTENT_DEFINE = exports.SortBy = exports.STORE_TYPE = exports.RoomType = exports.PostType = exports.PARAMS = exports.OrderStatus = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.NotificationType = exports.NewsStoreType = exports.CustomerCategory = exports.CoverType = exports.CategoryFood = void 0;
var STORE_TYPE = exports.STORE_TYPE = {
  FOOD: "Thức ăn",
  DRINK: "Thức uống"
};
var ORDER_FOOD_STATUS = exports.ORDER_FOOD_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
var ORDER_DELIVERY_STATUS = exports.ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
var ORDER_TRANSPORT_STATUS = exports.ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế",
  CUSTOMER_CANCEL: "Khách huỷ"
};
var TYPE_CONTENT_DEFINE = exports.TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  HOW_TO_DEPOSIT: "Hướng dẫn nạp điểm",
  HOW_TO_USE_DRIVER: "Cách sử dụng (Tài xế)",
  FAQ_DRIVER: "Câu hỏi thường gặp (Tài xế)",
  BADGE_POINT: "Cách tính điểm",
  GET_POINT: "Cách thức nhận điểm"
};
var ORDER_TYPE = exports.ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển xe máy",
  DELIVERY: "Giao hàng",
  TRANSPORT_CAR_7: "Di chuyển ô tô 7 chỗ",
  TRANSPORT_CAR_4: "Di chuyển ô tô 4 chỗ"
};
var PARAMS = exports.PARAMS = {
  COMMISSION_FOOD: "Thức ăn",
  COMMISSION_DELIVERY: "Giao hàng",
  COMMISSION_TRANSPORT: "Di chuyển ",
  MIN_DRIVER_BALANCE: "Tài khoản tối thiểu",
  DISTANCE_FIND_DRIVER: "Khoảng cách tìm tài xế",
  RATE_POINT: "Tỷ lệ quy đổi",
  REWARD_RATE_STORE: "Thưởng đánh giá cửa hàng",
  REWARD_RATE_ORDER: "Thưởng đánh giá đơn hàng"
};
var tagOptions = exports.tagOptions = {
  NOT_FOUND_DRIVER: {
    effect: "plain",
    type: "danger"
  },
  WAITING: {
    type: "warning",
    effect: "plain"
  },
  ACCEPT_ORDER: {
    type: "warning",
    effect: "plain"
  },
  DELIVERING: {
    type: "",
    effect: "plain"
  },
  DRIVER_CANCEL: {
    type: "danger",
    effect: "plain"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  },
  CUSTOMER_CANCEL: {
    type: "danger",
    effect: "dark"
  },
  FIND_DRIVER: {
    type: "danger",
    effect: "dark"
  },
  CANCEL: {
    type: "danger",
    effect: "dark"
  }
};
var VehicleType = exports.VehicleType = {
  BIKE: "Môtô 2 bánh",
  CAR_4: "Ôtô 4 bánh",
  CAR_7: "Ôtô 7 bánh"
};
var NewsStoreType = exports.NewsStoreType = {
  Plus: "PLUS",
  Coffee: "COFFEE"
};
var TransactionType = exports.TransactionType = {
  Deposit: "DEPOSIT",
  Withdraw: "WITHDRAW",
  Income: "INCOME"
};
var CategoryFood = exports.CategoryFood = {
  VEGETARIAN: "Món chay",
  SNACK: "Ăn vặt",
  RICE: "Cơm",
  MILK_TEA: "Trà sữa"
};
var CustomerCategory = exports.CustomerCategory = {
  ALL: "Tất cả",
  BIRTHDAY_IN_MONTH: "Sinh nhật trong tháng",
  OFFLINE: "Không hoạt động",
  FEMALE: "Nữ",
  BADGE: "Cấp bậc"
};
var CoverType = exports.CoverType = {
  HARD: "Bìa cứng",
  SOFT: "Bìa mềm"
};
var OrderStatus = exports.OrderStatus = {
  PENDING: "Đang xác nhận",
  COMPLETE: "Đã giao",
  CANCEL: "Huỷ",
  DELIVERING: "Đang vận chuyển"
};
var RoomType;
(function (RoomType) {
  RoomType["ROOM"] = "Ph\xF2ng";
  RoomType["APARTMENT"] = "C\u0103n h\u1ED9";
  RoomType["MINI_APARTMENT"] = "C\u0103n h\u1ED9 mini";
  RoomType["HOUSE"] = "Nguy\xEAn c\u0103n";
})(RoomType || (exports.RoomType = RoomType = {}));
var PostType;
(function (PostType) {
  PostType["LEASE"] = "Cho thu\xEA";
  PostType["RENT"] = "\u0110i thu\xEA";
  PostType["BUY"] = "Mua";
  PostType["SELL"] = "B\xE1n"; // Ban
})(PostType || (exports.PostType = PostType = {}));
var SortBy;
(function (SortBy) {
  SortBy["CREATED_AT_DESC"] = "Th\u1EDDi gian \u0111\u0103ng m\u1EDBi tr\u01B0\u1EDBc";
  SortBy["CREATED_AT_ASC"] = "Th\u1EDDi gian \u0111\u0103ng c\u0169 tr\u01B0\u1EDBc";
  SortBy["PRICE_DESC"] = "Gi\xE1 gi\u1EA3m d\u1EA7n";
  SortBy["PRICE_ASC"] = "Gi\xE1 t\u0103ng d\u1EA7n";
})(SortBy || (exports.SortBy = SortBy = {}));
var NotificationType;
(function (NotificationType) {
  NotificationType["Post"] = "POST";
  NotificationType["News"] = "NEWS";
})(NotificationType || (exports.NotificationType = NotificationType = {}));