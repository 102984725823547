"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.postApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var postApi = exports.postApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/post",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/post/".concat(id)
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/post/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  block: function block(id) {
    return (0, _request.default)({
      url: "/post/".concat(id, "/block"),
      method: "post"
    });
  },
  unblock: function unblock(id) {
    return (0, _request.default)({
      url: "/post/".concat(id, "/unblock"),
      method: "post"
    });
  }
};