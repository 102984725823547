"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.KYCStatusTrans = exports.KYCStatusColor = exports.KYCStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/defineProperty.js"));
var KYCStatus;
(function (KYCStatus) {
  KYCStatus["Waiting"] = "WAITING";
  KYCStatus["Submitted"] = "SUBMITTED";
  KYCStatus["Personal"] = "PERSONAL";
  KYCStatus["Company"] = "COMPANY";
})(KYCStatus || (exports.KYCStatus = KYCStatus = {}));
var KYCStatusTrans = exports.KYCStatusTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, KYCStatus.Waiting, "Chưa gửi yêu cầu"), KYCStatus.Submitted, "Đang chờ duyệt"), KYCStatus.Personal, "Cá nhân"), KYCStatus.Company, "Công ty");
var KYCStatusColor = exports.KYCStatusColor = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, KYCStatus.Waiting, "info"), KYCStatus.Submitted, ""), KYCStatus.Personal, "success"), KYCStatus.Company, "success");