"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-date-picker", {
    staticClass: "filter-item",
    attrs: {
      type: "daterange",
      "range-separator": "To",
      format: "dd/MM/yyyy",
      "value-format": "yyyy-MM-dd",
      "start-placeholder": _vm.$t("startDate"),
      "end-placeholder": _vm.$t("endDate")
    },
    on: {
      change: _vm.getDataChart
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _c("bar-chart", {
    ref: "chart",
    attrs: {
      convertDataBar: _vm.convertDataBar,
      "data-chart": _vm.dataChart,
      "pointer-name": "Đơn hàng",
      "attr-get": "total",
      format: "currency",
      "bar-color": "#FD6C9F"
    },
    on: {
      "update:dataChart": function updateDataChart($event) {
        _vm.dataChart = $event;
      },
      "update:data-chart": function updateDataChart($event) {
        _vm.dataChart = $event;
      }
    }
  }), _c("p", {
    staticClass: "chart-title"
  }, [_vm._v(_vm._s(_vm.$t("orderChart")))])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;