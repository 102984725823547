"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard-editor-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "filter-item"
  }, [_c("label", {
    attrs: {
      for: ""
    }
  }, [_vm._v("Tỉnh/thành")]), _c("el-select", {
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.cityId,
      callback: function callback($$v) {
        _vm.cityId = $$v;
      },
      expression: "cityId"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Tất cả",
      value: null
    }
  }), _vm._l(_vm.cities, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.nameWithType,
        value: item.id
      }
    });
  })], 2)], 1)]), _c("el-row", {
    attrs: {
      gutter: 32
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "chart-wrapper"
  }, [_c("CustomerChart", {
    attrs: {
      cityId: _vm.cityId
    }
  })], 1)]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "chart-wrapper"
  }, [_c("PostChart", {
    attrs: {
      cityId: _vm.cityId
    }
  })], 1)])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;