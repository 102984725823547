import { render, staticRenderFns } from "./CategoryModal.vue?vue&type=template&id=6b69f487"
import script from "./CategoryModal.vue?vue&type=script&lang=ts"
export * from "./CategoryModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b69f487')) {
      api.createRecord('6b69f487', component.options)
    } else {
      api.reload('6b69f487', component.options)
    }
    module.hot.accept("./CategoryModal.vue?vue&type=template&id=6b69f487", function () {
      api.rerender('6b69f487', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/news-category/components/CategoryModal.vue"
export default component.exports