"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "image-box",
    on: {
      click: _vm.handleChoose
    }
  }, [_c("img", {
    staticClass: "image",
    attrs: {
      src: _vm.$baseUrlMedia + _vm.data.url,
      alt: ""
    }
  }), _c("div", {
    staticClass: "name-box"
  }, [_c("span", [_vm._v(_vm._s(_vm.data.name))])]), _vm.type == _vm.MediaType.Choose ? _c("div", {
    staticClass: "selected",
    class: _vm.handleSelected
  }, [_c("i", {
    staticClass: "el-icon-check"
  })]) : _vm._e(), _vm.type == _vm.MediaType.Update ? _c("el-checkbox", {
    staticStyle: {
      position: "absolute",
      right: "10px",
      top: "10px"
    },
    attrs: {
      value: _vm.data.selected,
      label: ""
    },
    on: {
      change: _vm.handleSelect
    }
  }) : _vm._e(), _vm.type == _vm.MediaType.Update ? _c("div", {
    staticClass: "control-mask",
    on: {
      click: function click($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.handleSelect.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;