"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: _vm.dialogStatus == "update" ? _vm.$t("update") : _vm.$t("create"),
      visible: _vm.dialogVisible,
      width: "1200px",
      top: "15px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dialogLoading,
      expression: "dialogLoading"
    }]
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-width": "80px",
      "label-position": "top"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("title"),
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Loại tin tức",
      prop: "newsCategoryId"
    }
  }, [_c("el-select", {
    attrs: {
      filterable: "",
      size: "medium"
    },
    model: {
      value: _vm.formData.newsCategoryId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "newsCategoryId", $$v);
      },
      expression: "formData.newsCategoryId"
    }
  }, _vm._l(_vm.dataCategories, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Thumbnail (Tỷ lệ: 2/1)",
      prop: "thumbnail"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.formData.thumbnail,
      urlUpload: "/news/upload",
      height: 150,
      width: 300
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.formData, "thumbnail", path);
      }
    }
  })], 1)])], 1), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Image (Tỷ lệ: 1/1)",
      prop: "image"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("single-image", {
    attrs: {
      pathImage: _vm.formData.image,
      urlUpload: "/news/upload",
      height: 200,
      width: 200
    },
    on: {
      "upload:success": function uploadSuccess(path) {
        return _vm.$set(_vm.formData, "image", path);
      }
    }
  })], 1)])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("content"),
      prop: "body"
    }
  }, [_c("Tinymce", {
    ref: "editor",
    attrs: {
      width: "100%",
      height: 400
    },
    on: {
      onInit: _vm.handleInitTinymce
    },
    model: {
      value: _vm.formData.body,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "body", $$v);
      },
      expression: "formData.body"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))]), _c("el-button", {
    attrs: {
      loading: _vm.submitLoading,
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.dialogStatus == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("accept")))])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;