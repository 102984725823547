"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.customerApi = void 0;
exports.findAll = findAll;
exports.orderDelivery = orderDelivery;
exports.orderFood = orderFood;
exports.orderTransport = orderTransport;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
var customerApi = exports.customerApi = {
  affiliateChildren: function affiliateChildren(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/children")
    });
  },
  affiliateParent: function affiliateParent(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/parent")
    });
  },
  findAllSubmitted: function findAllSubmitted(params) {
    return (0, _request.default)({
      url: "/customer/kyc/submitted",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id)
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/kyc/cancel"),
      method: "POST"
    });
  },
  accept: function accept(id, data) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/kyc/accept"),
      method: "POST",
      data: data
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/delete"),
      method: "POST"
    });
  }
};
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/customer",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/customer",
    method: "post",
    data: data
  });
}
function update(customerId, data) {
  return (0, _request.default)({
    url: "/customer/".concat(customerId, "/update"),
    method: "post",
    data: data
  });
}
function orderFood(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderFood"),
    params: listQuery
  });
}
function orderDelivery(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderDelivery"),
    params: listQuery
  });
}
function orderTransport(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderTransport"),
    params: listQuery
  });
}