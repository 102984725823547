"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerChart = customerChart;
exports.getDashboardCustomer = getDashboardCustomer;
exports.getDashboardDriver = getDashboardDriver;
exports.getDashboardOrder = getDashboardOrder;
exports.getDashboardTransport = getDashboardTransport;
exports.orderBookingChart = orderBookingChart;
exports.orderShoppingChart = orderShoppingChart;
exports.orderTransportChart = orderTransportChart;
exports.postChart = postChart;
exports.today = void 0;
exports.top5Customer = top5Customer;
exports.top5Driver = top5Driver;
var _request = _interopRequireDefault(require("@/utils/request"));
function getDashboardOrder(params) {
  return (0, _request.default)({
    url: "/dashboard/order",
    method: "get",
    params: params
  });
}
var today = exports.today = function today() {
  return (0, _request.default)({
    url: "/dashboard/today"
  });
};
function getDashboardTransport(params) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport",
    method: "get",
    params: params
  });
}
function getDashboardDriver() {
  return (0, _request.default)({
    url: "/dashboard/driver",
    method: "get"
  });
}
function getDashboardCustomer(params) {
  return (0, _request.default)({
    url: "/dashboard/customer",
    method: "get",
    params: params
  });
}
function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customerGroupByDay",
    method: "get",
    params: listQuery
  });
}
function postChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/postGroupByDay",
    method: "get",
    params: listQuery
  });
}
function orderShoppingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/order/last30",
    method: "get",
    params: listQuery
  });
}
function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport/last30",
    method: "get",
    params: listQuery
  });
}
function orderBookingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/bookingTransport/last30",
    method: "get",
    params: listQuery
  });
}
function top5Driver() {
  return (0, _request.default)({
    url: "/dashboard/driver/top5",
    method: "get"
  });
}
function top5Customer() {
  return (0, _request.default)({
    url: "/dashboard/customer/top5",
    method: "get"
  });
}