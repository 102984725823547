"use strict";

var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCurrency = formatCurrency;
exports.formatNumberVN = formatNumberVN;
exports.secondToMinutes = void 0;
var _moment = _interopRequireDefault(require("moment"));
require("moment-duration-format");
function formatCurrency(n) {
  return new Intl.NumberFormat('Vi', {
    style: 'currency',
    currency: 'VND'
  }).format(n);
}
function formatNumberVN(n) {
  return new Intl.NumberFormat('Vi', {
    style: 'decimal'
  }).format(n);
}
var secondToMinutes = exports.secondToMinutes = function secondToMinutes(seconds) {
  var hour = Math.floor(_moment.default.duration(seconds, 'seconds').asHours());
  var minute = Math.floor(_moment.default.duration(seconds, 'seconds').asMinutes() % 60);
  var second = _moment.default.duration(seconds, 'seconds').seconds();
  if (second < 10) {
    second = "0".concat(second);
  }
  if (minute < 10) {
    minute = "0".concat(minute);
  }
  if (hour < 10) {
    hour = "0".concat(hour);
  }
  if (seconds > 3600) {
    return hour + ':' + minute + ':' + second;
  }
  return minute + ':' + second;
};