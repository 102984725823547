"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BarChart = _interopRequireDefault(require("../BarChart"));
var dashbord = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
var _default = exports.default = {
  components: {
    BarChart: _BarChart.default
  },
  props: ["cityId"],
  data: function data() {
    return {
      date: [(0, _moment.default)().startOf("month").startOf("days").format("YYYY-MM-DD"), (0, _moment.default)().endOf("month").endOf("days").format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  watch: {
    cityId: {
      handler: function handler(val) {
        this.getDataChart();
      },
      immediate: true
    }
  },
  created: function created() {
    // this.getDataChart();
  },
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;
      var listQuery = {
        from: this.date[0],
        to: this.date[1],
        cityId: this.city
      };
      dashbord.postChart(listQuery).then(function (res) {
        _this.dataChart = res.data;
        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};