"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.string.trim.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "form",
    attrs: {
      rules: _vm.rules,
      model: _vm.dataForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("oldPassword"),
      prop: "oldPass"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password"
    },
    model: {
      value: _vm.dataForm.oldPass,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "oldPass", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "dataForm.oldPass"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("newPassword"),
      prop: "newPass"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password"
    },
    model: {
      value: _vm.dataForm.newPass,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "newPass", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "dataForm.newPass"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("reNewPassword"),
      prop: "reNewPass"
    }
  }, [_c("el-input", {
    attrs: {
      type: "password"
    },
    model: {
      value: _vm.dataForm.reNewPass,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "reNewPass", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "dataForm.reNewPass"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      loading: _vm.loadingButton,
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;