"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.dialogStatus == "create" ? "Thêm mới thông báo" : "Cập nhật thông báo",
      visible: _vm.dialogVisible,
      width: "500px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      "label-position": "top",
      rules: _vm.rules,
      model: _vm.formData,
      "label-width": "80px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tiêu đề",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Thông báo danh cho",
      prop: "title"
    }
  }, [_c("el-radio", {
    attrs: {
      label: _vm.NotificationType.Post
    },
    on: {
      change: function change($event) {
        return _vm.fetchPosts();
      }
    },
    model: {
      value: _vm.formData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "type", $$v);
      },
      expression: "formData.type"
    }
  }, [_vm._v("Bài đăng")]), _c("el-radio", {
    attrs: {
      label: _vm.NotificationType.News
    },
    on: {
      change: function change($event) {
        return _vm.fetchNewss();
      }
    },
    model: {
      value: _vm.formData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "type", $$v);
      },
      expression: "formData.type"
    }
  }, [_vm._v("Tin tức")])], 1), _vm.formData.type == _vm.NotificationType.Post ? _c("el-form-item", {
    attrs: {
      label: "Bài đăng",
      prop: "postId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.formData.postId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "postId", $$v);
      },
      expression: "formData.postId"
    }
  }, _vm._l(_vm.posts, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.title,
        value: item.id
      }
    });
  }), 1)], 1) : _c("el-form-item", {
    attrs: {
      label: "Tin tức",
      prop: "newsId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      filterable: ""
    },
    model: {
      value: _vm.formData.newsId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "newsId", $$v);
      },
      expression: "formData.newsId"
    }
  }, _vm._l(_vm.newss, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.title,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("content"),
      prop: "body"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      maxlength: "300",
      "show-word-limit": "",
      autosize: {
        minRows: 4,
        maxRows: 10
      }
    },
    model: {
      value: _vm.formData.body,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "body", $$v);
      },
      expression: "formData.body"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v("Đóng")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: function click($event) {
        _vm.dialogStatus == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v("Đồng ý")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;