"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.symbol.description.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      title: _vm.$t("add"),
      visible: _vm.dialogVisible,
      width: "600px",
      top: "30px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.role,
      rules: _vm.rules,
      "label-width": "80px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("name"),
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.role.name,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "name", $$v);
      },
      expression: "role.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("description")
    }
  }, [_c("el-input", {
    attrs: {
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      type: "textarea"
    },
    model: {
      value: _vm.role.description,
      callback: function callback($$v) {
        _vm.$set(_vm.role, "description", $$v);
      },
      expression: "role.description"
    }
  })], 1)], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.dialogVisible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.buttonLoading
    },
    on: {
      click: _vm.createData
    }
  }, [_vm._v(_vm._s(_vm.$t("accept")))])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;