"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-input", _vm._b({
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: {
        alias: "decimal",
        groupSeparator: ".",
        rightAlign: false,
        autoGroup: true,
        repeat: 20,
        autoUnmask: true,
        allowMinus: false
      },
      expression: "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}"
    }],
    attrs: {
      value: _vm.value
    },
    on: {
      blur: _vm.handleBlur,
      input: function input(value) {
        return _vm.$emit("input", value);
      }
    }
  }, "el-input", _vm.$attrs, false), [_vm._t("default")], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;