"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    attrs: {
      title: _vm.status == "create" ? _vm.$t("add") : _vm.$t("update"),
      visible: _vm.visible,
      width: "600px",
      top: "30px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-position": "top",
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      label: _vm.$t("name")
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: _vm.$t("image"),
      prop: "image"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("single-image", {
    attrs: {
      width: 100,
      height: 100,
      pathImage: _vm.form.image,
      urlUpload: "/util/upload"
    },
    on: {
      "upload:success": _vm.uploadSuccess
    }
  })], 1)]), _c("el-form-item", {
    attrs: {
      label: "Hình ảnh active",
      prop: "imageActive"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "center"
    }
  }, [_c("single-image", {
    attrs: {
      width: 100,
      height: 100,
      pathImage: _vm.form.imageActive,
      urlUpload: "/util/upload"
    },
    on: {
      "upload:success": _vm.uploadImageActiveSuccess
    }
  })], 1)])], 1), _c("span", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loadingSubmit
    },
    on: {
      click: function click($event) {
        _vm.status == "create" ? _vm.createData() : _vm.updateData();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("accept")))])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;