"use strict";

var _interopRequireWildcard = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/Users/admin/Documents/project/203_PhongTro/203admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var dashboard = _interopRequireWildcard(require("@/api/dashboard"));
var _moment = _interopRequireDefault(require("moment"));
var _default = exports.default = {
  data: function data() {
    return {
      orderShopping: {},
      dataSouceDriver: {},
      dataSouceCustomer: {},
      dataOrderTransport: {},
      today: {},
      date: [(0, _moment.default)().subtract(7, "days").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")]
    };
  },
  components: {
    CountTo: _vueCountTo.default
  },
  methods: {
    getData: function getData() {
      var _this = this;
      var query = {
        from: this.date[0],
        to: this.date[1]
      };
      dashboard.today().then(function (res) {
        _this.today = res.data;
      });
      dashboard.getDashboardOrder(query).then(function (res) {
        _this.orderShopping = res.data;
      });
      dashboard.getDashboardCustomer(query).then(function (res) {
        _this.dataSouceCustomer = res.data;
      });
    }
  },
  created: function created() {
    this.getData();
  }
};