"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "form",
    attrs: {
      "label-position": "top",
      model: _vm.customerProfile,
      "label-width": "80px"
    }
  }, [_vm.customerProfile ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ảnh mặt trước CMND / CCCD"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.customerProfile.imageIdCardBefore,
      disabled: true
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Ảnh mặt sau CMND / CCCD"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.customerProfile.imageIdCardAfter,
      disabled: true
    }
  })], 1)], 1), _vm.customerProfile.companyName ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Tên công ty"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.customerProfile.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.customerProfile, "companyName", $$v);
      },
      expression: "customerProfile.companyName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Mã số thuế",
      prop: "taxCode"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.customerProfile.taxCode,
      callback: function callback($$v) {
        _vm.$set(_vm.customerProfile, "taxCode", $$v);
      },
      expression: "customerProfile.taxCode"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Người đại diện pháp luật",
      prop: "representationName"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.customerProfile.representationName,
      callback: function callback($$v) {
        _vm.$set(_vm.customerProfile, "representationName", $$v);
      },
      expression: "customerProfile.representationName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Địa chỉ"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.customerProfile.companyAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.customerProfile, "companyAddress", $$v);
      },
      expression: "customerProfile.companyAddress"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Giấy phép kinh doanh"
    }
  }, [_c("single-image", {
    attrs: {
      width: 200,
      height: 200,
      urlUpload: "/post/upload",
      pathImage: _vm.customerProfile.imageBusinessLicense,
      disabled: true
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;