"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es.function.name.js");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("span", [_vm._v("Loại khách hàng:")]), _c("el-select", {
    attrs: {
      placeholder: ""
    },
    model: {
      value: _vm.orderType,
      callback: function callback($$v) {
        _vm.orderType = $$v;
      },
      expression: "orderType"
    }
  }, _vm._l(_vm.orderTypes, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.name,
        value: item.key
      }
    });
  }), 1), _c("el-table", {
    attrs: {
      data: _vm.tableData[_vm.orderType],
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      "class-name": "text-center",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Họ tên",
      width: "200px",
      prop: "name"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "phone",
      label: "Điện thoại"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "orders",
      label: "Đơn hàng"
    }
  })], 1), _c("p", {
    staticClass: "chart-title"
  }, [_vm._v("Top 5 khách hàng trong tháng")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;